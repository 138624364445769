<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";


import AddressAutoComplete from '@/components/address-auto-complete-input'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
  required,
} from "vuelidate/lib/validators";

import appConfig from "@/app.config";
import { getDealBasicApi, } from '@/api/deal/basic'
import { getDealApi, } from '@/api/deal'


import { place_auto_complete } from '@/api/misc'



export default {
  page: {
    title: "Deal Contacts Edit",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "Deal Contacts Edit",
      items: [
        {
          text: "Deal",
        },
        {
          text: this.$route.query.deal_id,
          href: '/deal/deal_overview?deal_id=' + this.$route.query.deal_id
        },
        {
          text: "Contacts Edit",
          active: true,
        },
      ],

      address_items: [],
      addressSearch: '',


      contacts: [],
      del_contacts : [],

      type_options: [
        { value: 'Seller', text: 'Seller' },
        { value: 'Buyer', text: 'Buyer' },
        { value: 'Seller Lawyer', text: 'Seller Lawyer' },
        { value: 'Buyer Lawyer', text: 'Buyer Lawyer' },
      ],

      corp_options: [
        { value: '0', text: 'Individual' },
        { value: '1', text: 'Corporation' },
      ]

    };
  },
  components: {
    Layout,
    PageHeader,
    AddressAutoComplete,
    flatPickr

  },





  validations: {
    contacts: {
      $each: {
        name:       { required },
        first_name: { required },
        last_name:  { required },

      }
    }

  },

  watch: {
    addressSearch: function (addr) { this.onSearched(addr) }
  },


  methods: {
    formSubmit() {


      if (this.$v.$error == false) {
        getDealBasicApi().edit_contacts( this.$route.query.deal_id, this.contacts,  this.del_contacts).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message('Edit Successfully ');
            this.$router.push({name : 'deal-overview', query : {'deal_id' : this.$route.query.deal_id}})

          } else {
            this.$alertify.error("Edit Failed ret:" + res.errCode + '  ' + res.msg);
          }
        })
      }
    },

    onSearched(e) {
      place_auto_complete({ "str": e }).then((res) => {
        this.address_items = []

        res.data.map((d) => {
          this.address_items.push(d)
        })

      })
    },



    onHit(e ,c) {
      c.city           = e.city.trim()
      c.province       = e.province.trim()
      c.country        = e.country
      c.postal_code    = e.postal_code
      c.street         = e.street
     
    },

    onPostalCodeUpdate(e, c) {
      c.postal_code = e.postal_code
    },



    onAddContact() {
      this.contacts.push({
        first_name: '',
        last_name: '',
        name: '',

        street: '',
        unit: '',
        city: '',
        province: '',
        postal_code: '',
        country: '',
        is_coporation : 0,

      })
    },

    onContactRemove(idx) {
      let c = this.contacts[idx]
      this.contacts.splice(idx, 1)
      
      if (c.id) {
        this.del_contacts.push(c)
      }
    }

  },




  created() {

    getDealApi().detail({ order_deal_no: this.$route.query.deal_id }).then((res) => {
      if (res.errCode == 0) {
        this.deal = res.data
        this.contacts.push(...this.deal.buyers, ...this.deal.sellers, ...this.deal.lawyers)
      }

    })

  },

  mounted() {

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />




    <div class="card border shadow-none mb-2">

      <div class="card-header">
        <div class="col-auto">
          <div class="d-flex flex-wrap align-items-start justify-content-md-end gap-2 mb-3">


            <!-- <b-button  variant="info" v-b-modal.modal-send-report-dialog>Send Report</b-button> -->

            <b-button variant="info" @click="onAddContact">Add New Contact</b-button>
          </div>
        </div>

      </div>
      <div class="card-body">

        <form class="needs-validation" @submit.prevent="formSubmit">


          <div class="card border shadow-none mb-2" v-for="(c, idx) in contacts" :key="'contact_' + idx">
            <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-sm">
                  <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                    {{ idx + 1 }}
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="card-title">Contact</h5>
              </div>
            </div>
            <div class="card-body">

              <div class="row">

                <div class="row">

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom01">Type</label>
                      <b-form-select class="form-control" v-model="c.type" :options="type_options">

                      </b-form-select>
                    </div>
                  </div>


                  <div class="col-md-2" v-if="(c.type == 'Buyer' || c.type == 'Seller')">
                    <div class="mb-3">
                      <label for="validationCustom01">Is Corp</label>
                      <b-form-select class="form-control" v-model="c.is_coporation" :options="corp_options">

                      </b-form-select>
                    </div>
                  </div>


                  <div class="col-md-2" v-if="(c.type == 'Buyer' || c.type == 'Seller') && c.is_coporation == 0">
                    <div class="mb-3">
                      <label for="validationCustom01">First name</label>
                      <input id="validationCustom01" v-model="c.first_name" type="text" class="form-control"
                        placeholder="First name" :class="{
                          'is-invalid': $v.contacts.$each[idx].first_name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->
                  <div class="col-md-2"  v-if="(c.type == 'Buyer' || c.type == 'Seller') && c.is_coporation == 0">
                    <div class="mb-3">
                      <label for="validationCustom02">Last name</label>
                      <input id="validationCustom02" v-model="c.last_name" type="text" class="form-control"
                        placeholder="Last name" :class="{
                          'is-invalid': $v.contacts.$each[idx].first_name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].first_name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].first_name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->

                  <div class="col-md-3"  v-if="(c.type == 'Buyer' || c.type == 'Seller') && c.is_coporation == 1">
                    <div class="mb-3">
                      <label for="validationCustom02">Corp name</label>
                      <input id="validationCustom02" v-model="c.name" type="text" class="form-control"
                        placeholder="Corp name" :class="{
                          'is-invalid': $v.contacts.$each[idx].name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->


                  <div class="col-md-3"  v-if="(c.type == 'Buyer Lawyer' || c.type == 'Seller Lawyer')">
                    <div class="mb-3">
                      <label for="validationCustom02">Corp name</label>
                      <input id="validationCustom02" v-model="c.name" type="text" class="form-control"
                        placeholder="Last name" :class="{
                          'is-invalid': $v.contacts.$each[idx].name.$error,
                        }" />
                      <div v-if="$v.contacts.$each[idx].name.$error" class="invalid-feedback">
                        <span v-if="!$v.contacts.$each[idx].name.required">This value is
                          required.</span>
                      </div>
                    </div>
                  </div>
                  <!-- end col-->


                  <div class="col-md-2">
                    <div class="mb-3">
                      <label >Phone</label>
                      <input  v-model="c.phone" type="text" class="form-control"
                        placeholder="Phone" />
                    </div>
                  </div>
                  <!-- end col-->
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="validationCustom04">Email</label>
                      <input id="validationCustom04" v-model="c.email" type="text" class="form-control"
                        placeholder="Email" />

                    </div>
                  </div>
                  <!-- end col-->

                </div>
                <!-- end row-->

                <div class="row">
                  <div class="col-md-1">
                    <div class="mb-3">
                      <label >Unit</label>
                      <input  v-model="c.unit" type="text" class="form-control"
                        placeholder="Unit" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-3">
                      <label for="validationCustom04">Address</label>
                      <AddressAutoComplete :idx="idx" :fill_full="false" :query_postal_code="true" :init_data="{'address' : c.street, style_class:  {'form-control' : 'form-control'}}" v-model="c.street" @onSelected="onHit($event, c)" @onPostalCodeUpdate="onPostalCodeUpdate($event, c)" autocomplete="off" />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="city_option" class="form-label font-size-13 text-muted">City</label>
                      <input class="form-control" data-trigger v-model="c.city" />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label for="province_option" class="form-label font-size-13 text-muted">Province</label>
                      <input class="form-control" data-trigger v-model="c.province" />

                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label >Country</label>
                      <input  v-model="c.country" type="text" class="form-control"
                        placeholder="Country" />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label >Postal Code</label>
                      <input  v-model="c.postal_code" type="text" class="form-control"
                        placeholder="Postal Code" />
                    </div>
                  </div>
                </div>
                <!-- end row-->


               
                <div class="row" v-if="(c.type == 'Buyer Lawyer' || c.type == 'Seller Lawyer')">
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label >Contact Person</label>
                      <input  v-model="c.lawyer_contact_name" type="text" class="form-control"
                        placeholder="Contact Person" />
                    </div>
                  </div>
          
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label  class="form-label font-size-13 text-muted">Contact Email</label>
                      <input class="form-control" data-trigger v-model="c.email2" placeholder="Contact Email"/>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3">
                      <label   class="form-label font-size-13 text-muted">Contact Phone</label>
                      <input class="form-control" data-trigger v-model="c.phone2"  placeholder="Contact Phone"/>
                    </div>
                  </div>
                </div>


                <div class="row" v-if="c.type == 'Seller' || c.type == 'Buyer'">

                  <div class="col-md-2">
                    <div class="mb-3">
                      <label >Occuption</label>
                      <input  v-model="c.occupation" type="text" class="form-control"
                        placeholder="Occuption" />
                    </div>
                  </div>

                  <div class="col-md-1">
                    <div class="mb-3">
                      <label >DOB</label>
                      <flat-pickr v-model="c.birthday" placeholder="Select a date" class="form-control" ></flat-pickr>
                    </div>
                  </div>


                  <div class="col-md-1">
                    <div class="mb-3">
                      <label >ITN</label>
                      <input  v-model="c.itn" type="text" class="form-control"
                        placeholder="ITN/SIN" />
                    </div>
                  </div>

                  <div class="col-md-1">
                    <h5 class="font-size-14 mb-3">Is Canada</h5>
                    <div class="d-flex flex-wrap gap-2">
                      <input type="checkbox" :id="'is_canada_'+idx" switch="bool" v-model="c.is_non_resident" checked />
                      <label :for="'is_canada_'+idx" data-on-label="Yes" data-off-label="No" class="mb-0"></label>
                    </div>
                  </div>

                  <div class="col-md-1">
                    <h5 class="font-size-14 mb-3">Higher Risk</h5>
                    <div class="d-flex flex-wrap gap-2">
                      <input type="checkbox" :id="'high_risk_'+idx" switch="bool" v-model="c.higher_risk" checked />
                      <label :for="'high_risk_'+idx" data-on-label="Yes" data-off-label="No" class="mb-0"></label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <label >Reason of Higher Rish</label>
                      <input  v-model="c.reason_of_risk" type="text" class="form-control"
                        placeholder="Reason of Higher Rish" />
                    </div>
                  </div>


                </div>
                <!-- end row-->

                <div class="row">
                  <div class="text-end">
                    <button type="button" class="btn btn-danger w-sm" @click="onContactRemove(idx)" > Delete  </button>
                  </div>
                </div>
                <!-- end row -->


              </div>
              <!-- end row loop-->

            </div>
            <!-- end card body-->
          </div>
          <!-- end card -->
          <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4">
              <div class="d-flex flex-wrap justify-content-center gap-2">
                <b-button variant="secondary" type="button" @click="$router.push({name : 'deal-overview', query : {'deal_id' : $route.query.deal_id}})">Cancel</b-button>
                <b-button variant="primary" type="submit">Submit</b-button>
              </div>
            </div>
            <div class="col-md-4">

            </div>

          </div>
          <!-- end row-->

        </form>
      </div>
    </div>

  </Layout>
</template>
